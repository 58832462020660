import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import ServiciosCloud from "../../components/services/servicios_cloud"

const Migraciones = () => {
  const data = useStaticQuery(graphql`
    query Images_migraciones {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Servicios de Migración a la Nube | Qualoom</title>
        <meta name="title" content="Servicios de Migración a la Nube | Qualoom"/>
        <meta name="description" content="Servicios de Migración Cloud completo: Gestión, Implementación y Migración a la nube. Te ayudamos a migrar todos los datos de tu empresa minimizando gastos."/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/migraciones/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/migraciones/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/migraciones/"/>
        <meta property="og:title" content="Servicios de Migración a la Nube | Qualoom"/>
        <meta property="og:description" content="Servicios de Migración Cloud completo: Gestión, Implementación y Migración a la nube. Te ayudamos a migrar todos los datos de tu empresa minimizando gastos."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/migraciones/"/>
        <meta property="twitter:title" content="Servicios de Migración a la Nube | Qualoom"/>
        <meta property="twitter:description" content="Servicios de Migración Cloud completo: Gestión, Implementación y Migración a la nube. Te ayudamos a migrar todos los datos de tu empresa minimizando gastos."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>
        <meta property="fb:admins" content="338114870907726"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/migraciones/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Migraciones a Cloud", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/migraciones/", "name": "Migraciones a Cloud"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Servicios de Migración a la Nube</h1>
              <p>Cuando hablamos de migración a la nube hablamos del proceso de traslado a la infraestructura de un proveedor de tecnología de las aplicaciones, los datos y los procesos de explotación de una empresa.  Las principales ventajas de los servicios ofrecidos en la nube son la reducción de los costes TI, la flexibilidad y capacidad de alinear inversión con demanda, junto con otros aspectos de valor añadido por los proveedores como son la seguridad.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Para qué sirve la migración a Cloud?</h2>
          <hr></hr>
          <p>El proceso de la <b>migración a Cloud de aplicaciones y datos</b> consiste en cambiar su ubicación desde los servidores privados locales de una empresa a los servidores de un proveedor de nube pública, o entre distintas nubes. Las principales ventajas de este servicio son la reducción de los costes TI y la mejora en el rendimiento y la seguridad.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Desarrollamos planes de migración de servicios a la nube</h2>
          <hr></hr>
          <h3>Migración Cloud con base en tus necesidades</h3>
          <ul>
            <li>Analizamos con detalle tu situación para garantizar un plan de migración personalizado y centrado en tus necesidades</li>
            <li>Elaboramos una planificación para la ejecución de la migración y diseñaremos una infraestructura personalizada para tu empresa con el objetivo de reducir costes, mejorar el rendimiento y automatizar los procesos</li>
            <li>Definimos e implementamos una política para ejecutar la integración con sistemas externos, aplicaciones y otros dispositivos para garantizar la seguridad de tus integraciones y optimizar el tiempo de dedicación</li>
            <li>Te ayudamos a automatizar las tareas repetitivas que consumen tu tiempo y así optimizar el rendimiento de tu empresa</li>
            <li>Ofrecemos auditorías para hacer un seguimiento de tus procesos internos para identificar aquellos que son clave y optimizarlos </li>
          </ul>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué soluciones Cloud ofrece Qualoom?</h2><hr></hr>
          <ServiciosCloud/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Migraciones